export const AUTHOR_NAME = 'Rural Solutions';
export const COPYRIGHT_DEFAULT_YEAR = 2023;

export const DEFAULT_LANG = 'en';
export const ALL_LANGS = ['en'] as const;

export const DEFAULT_SITE_DOMAIN = 'ruralsolutions.co.uk';
export const DEFAULT_SITE_BASE_URL = 'https://www.ruralsolutions.co.uk';

// WARNING When updating here update in cms constants too
// WARNING When updating here update in create_redirects_file_from_cms too
export const PROJECT_PREFIX_URL = 'projects';
export const EXPERTISE_PREFIX_URL = 'expertises';
export const NEWS_ARTICLE_PREFIX_URL = 'news-and-insights';

export const SITE_DOMAIN =
  process.env.NODE_ENV === 'development'
    ? 'localhost:8000'
    : process.env.GATSBY_SITE_DOMAIN || DEFAULT_SITE_DOMAIN;

export const SITE_BASE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8000'
    : process.env.GATSBY_SITE_BASE_URL || DEFAULT_SITE_BASE_URL;

export const MAX_OG_DESCRIPTION_LENGTH = 300;

export const GOOGLE_OPTIMIZE_ID = process.env.GATSBY_GOOGLE_OPTIMIZE_ID;
export const SHOULD_RUN_GOOGLE_OPTIMIZE =
  process.env.NODE_ENV === `production` && !!GOOGLE_OPTIMIZE_ID;
