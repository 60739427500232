import React from 'react';

import { Color } from '../../graphql-fragments/Color';
import { clsx } from '../../utils/utils';
import * as styles from './Sidebar.module.scss';

export interface SidebarProps {
  color: Color;
  verticalPosition: 'left' | 'right' | 'center';
  className?: string;
}

const Sidebar = ({ color, verticalPosition, className }: SidebarProps): React.ReactElement => {
  return (
    <div
      className={clsx(
        className,
        styles.container,
        verticalPosition === 'right' && styles.rightPosition,
        verticalPosition === 'center' && styles.centerPosition,
      )}
      style={{ backgroundColor: color }}
    ></div>
  );
};

export default Sidebar;
