/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  PortableText,
  PortableTextBlock,
  PortableTextMarkComponentProps,
  PortableTextReactComponents,
  PortableTextTypeComponentProps,
} from '@portabletext/react';
import React from 'react';
import { useCookieSettings } from '../contexts/CookieSettingsContext';
import { ButtonLinkType, PageReferenceType } from '../graphql-fragments/ButtonLink';
import { RawPortableText } from '../types/types';
import { getReferenceUrl, SANITY_TYPE_TO_TYPENAME, slugify } from '../utils/utils';
import * as styles from './portableText.module.scss';
import ButtonLink from './ui/ButtonLink';
import GenericLink from './ui/GenericLink';
import Sidebar from './ui/Sidebar';

function getIdFromValue(value: PortableTextBlock): string | undefined {
  return slugify(
    value.children.map(child => (typeof child.text === 'string' ? child.text : '')).join(''),
  );
}

export const components = {
  block: {
    h2: ({ value, children }) => <h2 id={getIdFromValue(value)}>{children}</h2>,
    h3: ({ value, children }) => <h3 id={getIdFromValue(value)}>{children}</h3>,
    h4: ({ value, children }) => <h4 id={getIdFromValue(value)}>{children}</h4>,
    h5: ({ value, children }) => <h5 id={getIdFromValue(value)}>{children}</h5>,
  },
  marks: {
    internalLink: (
      props: PortableTextMarkComponentProps<{
        _type: 'internalLink';
        linkToCookieSettings?: boolean;
        reference?: {
          slug: { current: string };
          _type: PageReferenceType;
        };
        anchorLink?: string;
      }>,
    ) => {
      const { showCookieSettingsModal } = useCookieSettings();

      if (props.value!.linkToCookieSettings) {
        return (
          <a
            className={styles.link}
            onClick={() => {
              showCookieSettingsModal();
            }}
          >
            {props.children}
          </a>
        );
      } else {
        return (
          <GenericLink
            to={
              (props.value!.reference
                ? getReferenceUrl(
                    SANITY_TYPE_TO_TYPENAME[props.value!.reference._type],
                    props.value!.reference.slug.current,
                  )
                : '') +
              (props.value!.anchorLink ? '#' + props.value!.anchorLink.replace('#', '') : '')
            }
          >
            {props.children}
          </GenericLink>
        );
      }
    },

    externalLink: (
      props: PortableTextMarkComponentProps<{
        _type: 'externalLink';
        url: string;
      }>,
    ) => {
      return <GenericLink to={props.value!.url}>{props.children}</GenericLink>;
    },
  },
  types: {
    image: (props: PortableTextTypeComponentProps<{ asset: { url: string } }>) => {
      return (
        props.value?.asset && (
          <figure>
            <img src={props.value.asset.url} alt="" />
          </figure>
        )
      );
    },
    buttonLink: (
      props: PortableTextTypeComponentProps<
        ButtonLinkType & {
          _type: 'buttonLink';
        }
      >,
    ) => {
      return (
        <div className={styles.buttonLink}>
          <ButtonLink
            to={
              (props.value!.pageReference
                ? getReferenceUrl(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                    SANITY_TYPE_TO_TYPENAME[props.value!.pageReference._type],
                    props.value!.pageReference.slug.current,
                  )
                : '') +
              (props.value!.anchorLink ? '#' + props.value!.anchorLink.replace('#', '') : '')
            }
            color={props.value!.color}
            secondary={props.value!.isButtonOutlined}
          >
            {props.value!.text}
          </ButtonLink>
        </div>
      );
    },

    quote: (
      props: PortableTextTypeComponentProps<{
        quote: string;
        text: RawPortableText;
      }>,
    ) => {
      return (
        <div className={styles.quoteContainer}>
          <Sidebar color="#D6FF4F" verticalPosition="left" className={styles.sidebar} />
          <blockquote className={styles.quote}>"{props.value.quote}"</blockquote>
          <div className={styles.text}>
            <PortableText value={props.value.text} components={components} />
          </div>
        </div>
      );
    },

    horizontalLine: () => {
      return (
        <hr
          className={styles.horizontalLine}
          style={{
            borderStyle: 'solid',
            borderBottomWidth: 2,
            borderColor: '#c9c4bb',
          }}
        ></hr>
      );
    },
  },
  list: {
    number: ({ children, value }) =>
      value.level === 2 ? <ol type="a">{children}</ol> : <ol>{children}</ol>,
  },
} satisfies Partial<PortableTextReactComponents>;
