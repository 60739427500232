import React, { useEffect, useRef } from 'react';

interface DangerouslySetHtmlContentProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  html: string;
  allowRerender?: boolean;
}

export default function DangerouslySetHtmlContent({
  html,
  allowRerender,
  ...restProps
}: DangerouslySetHtmlContentProps) {
  const divRef = useRef<HTMLDivElement | null>(null);
  const shouldRender = useRef(true);

  useEffect(() => {
    if (!html || !divRef.current) throw new Error("html prop can't be null");
    if (!shouldRender.current) return;
    shouldRender.current = !!allowRerender;

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ''; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html, divRef]);

  // We remove 'dangerouslySetInnerHTML' from props passed to the div
  delete restProps.dangerouslySetInnerHTML;
  return <div ref={divRef} {...restProps}></div>;
}
