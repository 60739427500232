import React from 'react';

import Button, { ButtonProps } from './Button';
import * as styles from './ButtonLink.module.scss';
import GenericLink from './GenericLink';

export interface ButtonLinkProps extends ButtonProps {
  to: string;
}

const ButtonLink = ({ to, ...restProps }: ButtonLinkProps): React.ReactElement => {
  return (
    <GenericLink className={styles.link} to={to} tabIndex={-1}>
      <Button {...restProps}></Button>
    </GenericLink>
  );
};

export default ButtonLink;
