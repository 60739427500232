/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from 'react';
// import { I18nextProvider } from 'react-i18next';

import { CookieSettingsProvider } from './src/contexts/CookieSettingsContext';
import { LocationContext } from './src/contexts/LocationContext';
import { GlobalStateProvider } from './src/state/globalStateContext';
import './src/translation/i18n';

export const wrapPageElement = ({ element, props }) => {
  return (
    /* <I18nextProvider i18n={i18n}> */
    <GlobalStateProvider>
      <LocationContext.Provider value={props.location}>
        <CookieSettingsProvider>{element}</CookieSettingsProvider>
      </LocationContext.Provider>
    </GlobalStateProvider>
    /* </I18nextProvider> */
  );
};
