/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import DangerouslySetHtmlContent from '../components/lib/DangerouslySetHtmlContent';
import { CookieBannerProps } from '../components/ui/CookieBanner';
import ModalOverlay from '../components/ui/ModalOverlay';
import TabbedCardList, { Tab } from '../components/ui/TabbedCardList';
import {
  getCookieValue,
  removeCookie,
  setCookie,
  signAndObfuscate,
  verifyAndDeobfuscate,
} from '../utils/utils';
import * as styles from './CookieSettingsContext.module.scss';

const COOKIE_NAME = 'cookieSettings';
const COOKIE_BANNER_DISMISSED_KEY = 'cookieBarDismissed';
const COOKIE_SIGNING_SECRET = 'Yk5FbOX5ElL';

interface CookieSettingsCookieValue {
  nonEssentialCookiesAccepted: boolean;
  version?: number;
}

interface QueryData {
  sanityWebsite: {
    cookieBannerConfig: CookieBannerProps;
  };
}

interface CookieSettingsContextValue {
  isCookieBannerOpen: boolean;
  closeCookieBanner: () => void;
  acceptAllCookies: () => void;
  showCookieSettingsModal: () => void;
}

// We assume this CookieSettingsContext will never be used unless inside
// the CookieSettingsContext.Provider, and so the default is never used.
const CookieSettingsContext = createContext<CookieSettingsContextValue>(
  {} as CookieSettingsContextValue,
);

export const useCookieSettings = () => useContext(CookieSettingsContext);

interface CookieSettingsProviderProps {
  children: React.ReactNode;
}

export function CookieSettingsProvider({ children }: CookieSettingsProviderProps) {
  const queryData: QueryData = useStaticQuery(graphql`
    {
      sanityWebsite {
        cookieBannerConfig {
          _rawText(resolveReferences: { maxDepth: 6 })
          versionNumber
          acceptButtonText
          settingsButtonText
          cookieSettingsModal {
            privacyOverview {
              tabLabel
              _rawTabContent(resolveReferences: { maxDepth: 6 })
            }
            essentialCookies {
              tabLabel
              _rawTabContent(resolveReferences: { maxDepth: 6 })
            }
            nonEssentialCookies {
              tabLabel
              _rawTabContent(resolveReferences: { maxDepth: 6 })
            }
          }
          nonEssentialTrackingCode {
            head
            body
            footer
          }
        }
      }
    }
  `);

  const { cookieSettingsModal, nonEssentialTrackingCode, versionNumber } =
    queryData.sanityWebsite.cookieBannerConfig;

  const [nonEssentialCookiesEnabled, setNonEssentialCookiesEnabled] = useState(false);
  const [isCookieBannerOpen, setIsCookieBannerOpen] = useState(false);
  const [isCookieSettingsModalOpen, setIsCookieSettingsModalOpen] = useState(false);
  const headScriptsContainer = useRef<HTMLDivElement | null>(null);
  const bodyScriptsContainer = useRef<HTMLDivElement | null>(null);
  const footerScriptsContainer = useRef<HTMLDivElement | null>(null);

  function showCookieSettingsModal() {
    setIsCookieSettingsModalOpen(true);
    window.history.pushState({}, '');
  }

  function closeCookieBanner() {
    setIsCookieBannerOpen(false);
    window.sessionStorage.setItem(COOKIE_BANNER_DISMISSED_KEY, 'true');
  }

  function acceptAllCookies() {
    setIsCookieBannerOpen(false);
    storeCookieSettingsChoices({ nonEssentialCookiesAccepted: true });
  }

  function activateNonEssentialCookiesScripts() {
    headScriptsContainer.current = document.querySelector('#headScriptsContainer');
    bodyScriptsContainer.current = document.querySelector('#bodyScriptsContainer');
    footerScriptsContainer.current = document.querySelector('#footerScriptsContainer');
    setNonEssentialCookiesEnabled(true);
  }

  useEffect(() => {
    const handleBackButton = (event: PopStateEvent) => {
      if (isCookieSettingsModalOpen) {
        event.preventDefault();
        setIsCookieSettingsModalOpen(false);
      }
    };
    window.addEventListener('popstate', handleBackButton);
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [isCookieSettingsModalOpen]);

  useEffect(() => {
    const cookieSettingsValue = verifyAndDeobfuscate(
      getCookieValue(COOKIE_NAME),
      COOKIE_SIGNING_SECRET,
    );
    if (cookieSettingsValue) {
      const cookieSettings: CookieSettingsCookieValue = JSON.parse(cookieSettingsValue);
      if (cookieSettings.version && versionNumber && cookieSettings.version !== versionNumber) {
        removeCookie(COOKIE_NAME);
        setIsCookieBannerOpen(true);
      } else {
        if (cookieSettings.nonEssentialCookiesAccepted) {
          activateNonEssentialCookiesScripts();
        }
      }
    } else {
      removeCookie(COOKIE_NAME);
      const cookieBarDismissed = window.sessionStorage.getItem(COOKIE_BANNER_DISMISSED_KEY);
      if (!cookieBarDismissed) {
        setIsCookieBannerOpen(true);
      }
    }
  }, []);

  function storeCookieSettingsChoices({
    nonEssentialCookiesAccepted,
  }: {
    nonEssentialCookiesAccepted: boolean;
  }) {
    setCookie(
      COOKIE_NAME,
      signAndObfuscate(
        JSON.stringify({
          nonEssentialCookiesAccepted,
          version: versionNumber,
        } satisfies CookieSettingsCookieValue),
        COOKIE_SIGNING_SECRET,
      ),
      { expires: 3650, sameSite: 'strict' },
    );
    if (nonEssentialCookiesEnabled && !nonEssentialCookiesAccepted) {
      // Reload window so it loads without non essential scripts
      window.location.reload();
    } else if (!nonEssentialCookiesEnabled && nonEssentialCookiesAccepted) {
      activateNonEssentialCookiesScripts();
    }
  }

  const tabs: Array<Tab> = [
    {
      title: cookieSettingsModal.privacyOverview.tabLabel || 'Privacy Overview',
      text: cookieSettingsModal.privacyOverview._rawTabContent,
    },
    {
      title: cookieSettingsModal.essentialCookies.tabLabel || 'Essential Cookies',
      text: cookieSettingsModal.essentialCookies._rawTabContent,
      toggleButton: {
        id: 'essentialCookies',
        initialState: true,
        disabled: true,
      },
    },
    {
      title: cookieSettingsModal.nonEssentialCookies.tabLabel || 'Non-Essential Cookies',
      text: cookieSettingsModal.nonEssentialCookies._rawTabContent,
      toggleButton: {
        id: 'nonEssentialCookies',
        initialState: nonEssentialCookiesEnabled,
      },
    },
  ];

  return (
    <CookieSettingsContext.Provider
      value={{
        isCookieBannerOpen,
        closeCookieBanner,
        acceptAllCookies,
        showCookieSettingsModal,
      }}
    >
      <ModalOverlay
        open={isCookieSettingsModalOpen}
        onClose={() => {
          window.history.back();
        }}
        modalClassName={styles.modal}
      >
        <div className={styles.modalContainer}>
          <TabbedCardList
            key={String(isCookieSettingsModalOpen)}
            tabs={tabs}
            buttons={[
              {
                title: 'Enable all',
                checkEnabled: togglesStates => !togglesStates.nonEssentialCookies,
                onButtonClick: () => {
                  storeCookieSettingsChoices({ nonEssentialCookiesAccepted: true });
                  setIsCookieSettingsModalOpen(false);
                  setIsCookieBannerOpen(false);
                },
              },
              {
                title: 'Save changes',
                checkEnabled: togglesStates =>
                  togglesStates.nonEssentialCookies !== nonEssentialCookiesEnabled,
                onButtonClick: togglesStates => {
                  storeCookieSettingsChoices({
                    nonEssentialCookiesAccepted: togglesStates.nonEssentialCookies,
                  });
                  setIsCookieSettingsModalOpen(false);
                  setIsCookieBannerOpen(false);
                },
              },
            ]}
          />
        </div>
      </ModalOverlay>
      {nonEssentialCookiesEnabled && nonEssentialTrackingCode && (
        <>
          {nonEssentialTrackingCode.head &&
            createPortal(
              <DangerouslySetHtmlContent
                style={{ display: 'none' }}
                html={nonEssentialTrackingCode.head}
              ></DangerouslySetHtmlContent>,
              headScriptsContainer.current!,
            )}
          {nonEssentialTrackingCode.body &&
            createPortal(
              <DangerouslySetHtmlContent
                style={{ display: 'none' }}
                html={nonEssentialTrackingCode.body}
              ></DangerouslySetHtmlContent>,
              bodyScriptsContainer.current!,
            )}
          {nonEssentialTrackingCode.footer &&
            createPortal(
              <DangerouslySetHtmlContent
                style={{ display: 'none' }}
                html={nonEssentialTrackingCode.footer}
              ></DangerouslySetHtmlContent>,
              footerScriptsContainer.current!,
            )}
        </>
      )}
      {children}
    </CookieSettingsContext.Provider>
  );
}
