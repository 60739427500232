// extracted by mini-css-extract-plugin
export var accordionsContainer = "TabbedCardList-module--accordionsContainer--3e240";
export var active = "TabbedCardList-module--active--02a37";
export var buttonsContainer = "TabbedCardList-module--buttonsContainer--27fca";
export var disabled = "TabbedCardList-module--disabled--a250e";
export var divider = "TabbedCardList-module--divider--21066";
export var enabled = "TabbedCardList-module--enabled--45397";
export var section = "TabbedCardList-module--section--4e417";
export var sectionContainer = "TabbedCardList-module--sectionContainer--1defa";
export var slider = "TabbedCardList-module--slider--c06b5";
export var tabTitle = "TabbedCardList-module--tabTitle--03de6";
export var tabbedListContainerDesktop = "TabbedCardList-module--tabbedListContainerDesktop--c3eef";
export var tabbedListContainerMobile = "TabbedCardList-module--tabbedListContainerMobile--0a667";
export var tabsContainer = "TabbedCardList-module--tabsContainer--8d8dc";
export var tabsTextContainer = "TabbedCardList-module--tabsTextContainer--a28a6";
export var tabsTitleContainer = "TabbedCardList-module--tabsTitleContainer--109bd";
export var text = "TabbedCardList-module--text--8e5ef";
export var textContainerTitle = "TabbedCardList-module--textContainerTitle--16f7b";
export var toggle = "TabbedCardList-module--toggle--75934";
export var toggleContainer = "TabbedCardList-module--toggleContainer--763e1";
export var toggleLabel = "TabbedCardList-module--toggleLabel--03d97";
export var visuallyHidden = "TabbedCardList-module--visuallyHidden--dc2e9";
export var withMinHeight = "TabbedCardList-module--withMinHeight--0120f";