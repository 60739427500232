// extracted by mini-css-extract-plugin
export var accordion = "Accordion-module--accordion--a012b";
export var accordionBox = "Accordion-module--accordionBox--597d2";
export var accordionOpen = "Accordion-module--accordionOpen--cfe9c";
export var body = "Accordion-module--body--a14b8";
export var button = "Accordion-module--button--b7450";
export var content = "Accordion-module--content--b9ff7";
export var iconContainer = "Accordion-module--iconContainer--c3753";
export var iconHorizontalLine = "Accordion-module--iconHorizontalLine--d51b7";
export var iconVerticalLine = "Accordion-module--iconVerticalLine--b9cc2";
export var title = "Accordion-module--title--b0397";
export var titleBox = "Accordion-module--titleBox--a90c8";
export var titleContainer = "Accordion-module--titleContainer--a92d0";