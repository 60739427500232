import React from 'react';

import { Color, getColorValueByColorTitle } from '../../graphql-fragments/Color';
import { clsx } from '../../utils/utils';
import * as styles from './Button.module.scss';

export interface ButtonProps
  extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'children'> {
  className?: string;
  color?: Color;
  secondary?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  appearsOnlyInMobile?: boolean;
  children: React.ReactNode;
}

const Button = ({
  className,
  color,
  secondary,
  disabled,
  onClick,
  appearsOnlyInMobile,
  children,
}: ButtonProps): React.ReactElement => {
  const lightColor =
    color === getColorValueByColorTitle('beige') ||
    color === getColorValueByColorTitle('lightGrey') ||
    color === getColorValueByColorTitle('yellow') ||
    color === getColorValueByColorTitle('white');

  return (
    <button
      className={clsx(
        className,
        styles.container,
        (!color || secondary) && styles.secondary,
        lightColor && styles.lightColor,
        appearsOnlyInMobile && styles.appearsOnlyInMobile,
        disabled && styles.disabled,
      )}
      onClick={onClick}
      style={secondary ? { border: `1px solid ${color}` } : { backgroundColor: color }}
    >
      <span className={styles.children} style={secondary ? { color: color } : {}}>
        {children}
      </span>
    </button>
  );
};

export default Button;
