import { GatsbyLinkProps, Link } from 'gatsby';
import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { checkIsInternalUrl, clsx, getInternalUrlPath } from '../../utils/utils';
import * as styles from './GenericLink.module.scss';

export default React.forwardRef(function GenericLink<T>(
  { to, className, children, ...restProps }: GatsbyLinkProps<T>,
  ref,
): React.ReactElement {
  if (checkIsInternalUrl(to)) {
    if (to.startsWith('#')) {
      return (
        <AnchorLink
          ref={ref}
          href={getInternalUrlPath(to)}
          {...restProps}
          className={clsx(styles.link, className)}
        >
          {children}
        </AnchorLink>
      );
    } else {
      return (
        <Link
          ref={ref}
          to={getInternalUrlPath(to)}
          {...restProps}
          className={clsx(styles.link, className)}
        >
          {children}
        </Link>
      );
    }
  } else {
    return (
      <a
        className={clsx(styles.link, className)}
        ref={ref}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...restProps}
      >
        {children}
      </a>
    );
  }
});
