import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: process.env.GATSBY_SENTRY_DSN_URL,
  environment: process.env.NODE_ENV,
  enabled: !!process.env.GATSBY_SENTRY_DSN_URL && process.env.NODE_ENV === 'production',
  beforeSend(event, hint) {
    // This is where we can filter events
    return event;
  },
});
